import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import useDidMountEffect from 'hooks/useDidMountEffect';

// JS
import * as Server from 'assets/js/Server';
import * as Common from 'assets/js/Common';

import SupportHead from 'components/Support/SupportHead';

const SupportConsult = () => {
  const { t, i18n } = useTranslation();
  const nameRef = useRef();
  const phoneRef = useRef();
  const emailRef = useRef();
  const titleRef = useRef();
  const contentRef = useRef();
  const inputEl = useRef(null);

  const [isMobile, setIsMobile] = useState(window.innerWidth < 1025);
  const [search, setSearch] = useState({
    language: '',
    isEng: false,
  });
  const [inquiry, setInquiry] = useState({
    name: '',
    email: '',
    phone: '',
    title: '',
    content: '',
    fileName: '',
    fileUrl: '',
  });
  const [agree, setAgree] = useState(false);
  const [isWrite, setIsWrite] = useState(true);
  const [policy, setPolicy] = useState({
    language: '',
    content: '',
    seq: ''
  });

  const lang = {
    ko: {
      language: 'KOR',
      isEng: false,
    },
    en: {
      language: 'ENG',
      isEng: true
    },
    cn: {
      language: 'CHN',
      isEng: false
    }
  };

  useEffect(() => {
    setSearch(lang[i18n.language]);

    const handleResize = () => {
      setIsMobile(window.innerWidth < 1025);
    };

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useDidMountEffect(() => {
    setSearch(lang[i18n.language]);
  }, [i18n.language]);

  useDidMountEffect(() => {
    if (!Common.isEmpty(search.language)) {
      callPolicy();
    }
  }, [search]);

  const callPolicy = () => {
    Server.sendGet('ustKr/policy', {
      language: search.language,
    }, getPolicy).then();
  };
  const getPolicy = res => {
    setPolicy(res['resultObject']);
  };
  const validationCheck = () => {
    const validationValue = ['name', 'phone', 'email', 'title', 'content'];
    const validationName = [
      t('support.consult.placeholder.first'),
      t('support.consult.placeholder.second'),
      t('support.consult.placeholder.third'),
      t('support.consult.placeholder.fourth'),
      t('support.consult.placeholder.fifth'),
    ];
    const refArr = [nameRef, phoneRef, emailRef, titleRef, contentRef];

    for (let i = 0; i < validationValue.length; i++) {
      if (Common.isEmpty(inquiry[validationValue[i]])) {
        alert(validationName[i]);

        refArr[i].current?.focus();

        return false;
      }
    }

    if (!Common.isEmail(inquiry.email)) {
      alert(t('support.consult.alert.second'));

      emailRef.current?.focus();

      return false;
    }

    return true;
  };
  const onInquiry = () => {
    if (!agree) {
      alert(t('support.consult.alert.third'));

      return;
    }

    if (validationCheck() && isWrite) {
      setIsWrite(false);

      Server.sendPost('ustKr/inquiry', inquiry, writeInquiry).then();
    }
  };
  const writeInquiry = () => {
    alert(t('support.consult.alert.fourth'));

    window.location.reload();
  };
  const fileToUrl = e => {
    const file = e.target.files[0];

    if (!file) {
      return;
    }

    const maxSize = 10 * 1024 * 1024; // 10MB를 byte 단위로 변환

    if (file.size > maxSize) {
      alert(t('support.consult.alert.fifth'));

      return;
    }

    const allowedTypes = ['image/jpeg', 'image/gif', 'image/png', 'application/pdf'];

    if (!allowedTypes.includes(file.type)) {
      alert(t('support.consult.alert.sixth'));

      e.target.value = '';

      return;
    }

    const formData = new FormData();

    formData.append('file', file);

    Server.sendS3Upload(formData).then(res => {
      if (res.data['resultObject']) {
        setInquiry(() => {
          return {
            ...inquiry,
            fileUrl: res.data['resultObject']?.['s3Url'],
            fileName: file.name
          };
        });
      } else {
        alert(t('support.consult.alert.seventh'));
      }
    }).catch(() => {
      alert(t('support.consult.alert.seventh'));
    }).finally(() => {
      e.target.value = '';
    });
  };

  return (
    <React.Fragment>
      {/* 루트별 공통 비주얼 헤더 */}
      <SupportHead/>

      {/* 컨텐츠 */}
      <Wrap>
        {/* Consult */}
        <section className='consult_sec'>
          <div className='sec_inner'>
            <div className='page_tit'>
              <h4>{t('support.consult.title')}</h4>
              <p>{t('support.consult.sub')}</p>
            </div>
            <div className={search.isEng ? 'eng consult_in' : 'consult_in'}>
              <table>
                <tbody>
                <tr>
                  <th><label htmlFor='cs_name'>{t('support.consult.label.first')}</label></th>
                  <td>
                    <input
                      type='text'
                      name='cs_name'
                      id='cs_name'
                      placeholder={t('support.consult.placeholder.first')}
                      ref={nameRef}
                      value={inquiry.name}
                      onChange={e => {
                        setInquiry(() => {
                          return {
                            ...inquiry,
                            name: e.target.value
                          };
                        });
                      }}
                      required
                    />
                  </td>
                </tr>
                <tr>
                  <th><label htmlFor='cs_name'>{t('support.consult.label.second')}</label></th>
                  <td>
                    <input
                      type='phone'
                      name='cs_phone'
                      id='cs_phone'
                      placeholder={t('support.consult.placeholder.second')}
                      ref={phoneRef}
                      value={inquiry.phone}
                      onChange={e => {
                        e.target.value = Common.onlyNumber(e.target.value);

                        setInquiry(() => {
                          return {
                            ...inquiry,
                            phone: e.target.value
                          };
                        });
                      }}
                      required
                    />
                  </td>
                </tr>
                <tr>
                  <th><label htmlFor='cs_name'>{t('support.consult.label.third')}</label></th>
                  <td>
                    <input
                      type='text'
                      name='cs_email'
                      id='cs_email'
                      placeholder={t('support.consult.placeholder.third')}
                      ref={emailRef}
                      value={inquiry.email}
                      onChange={e => {
                        setInquiry(() => {
                          return {
                            ...inquiry,
                            email: e.target.value
                          };
                        });
                      }}
                      required
                    />
                  </td>
                </tr>
                <tr>
                  <th><label htmlFor='cs_subject'>{t('support.consult.label.fourth')}</label></th>
                  <td>
                    <input
                      type='text'
                      name='cs_subject'
                      id='cs_subject'
                      placeholder={t('support.consult.placeholder.fourth')}
                      ref={titleRef}
                      value={inquiry.title}
                      onChange={e => {
                        setInquiry(() => {
                          return {
                            ...inquiry,
                            title: e.target.value
                          };
                        });
                      }}
                      required
                    />
                  </td>
                </tr>
                <tr>
                  <th><label htmlFor='cs_content'>{t('support.consult.label.fifth')}</label></th>
                  <td>
                    <textarea
                      placeholder={t('support.consult.placeholder.fifth')}
                      value={inquiry.content}
                      ref={contentRef}
                      onChange={e => {
                        setInquiry(() => {
                          return {
                            ...inquiry,
                            content: e.target.value
                          };
                        });
                      }}
                      required
                    />
                  </td>
                </tr>
                <tr>
                  <th><label>{t('support.consult.label.sixth')}</label></th>
                  <td>
                    <div className='cs_file_box'>
                      <label htmlFor='cs_file'>
                        <div className='custom_file'>
                          {inquiry.fileName ?
                            <p className='file_name'>{inquiry.fileName}</p> :
                            <p className='file_name'>{t('support.consult.btn.first')}</p>
                          }
                          <div className='file_btn'>{t('support.consult.btn.second')}</div>
                        </div>
                      </label>
                      <input
                        type='file'
                        name='cs_file'
                        id='cs_file'
                        ref={inputEl}
                        accept='image/jpeg, image/gif, image/png, application/pdf'
                        onChange={fileToUrl}
                      />
                    </div>
                    <p className='files_info'>{t('support.consult.placeholder.sixth')}</p>
                  </td>
                </tr>
                </tbody>
              </table>

              <div className='privacy_in'>
                <h6>{t('support.consult.label.seventh')}</h6>
                <div className='privacy_con'>
                  <div className='terms_desc' dangerouslySetInnerHTML={{ __html: policy?.content }}></div>
                  <div className='terms_check'>
                    <input
                      type='checkbox'
                      name='terms_chk'
                      id='terms_chk'
                      checked={agree}
                      onChange={e => setAgree(e.target.checked)}
                    />
                    <label htmlFor='terms_chk'>{t('support.consult.label.eighth')}</label>
                  </div>
                </div>
              </div>
              <div className='consult_btn'>
                <button onClick={onInquiry}>{t('support.consult.btn.third')}</button>
              </div>
            </div>
          </div>
        </section>
      </Wrap>
    </React.Fragment>
  );
};

const Wrap = styled.div`
  position: relative;

  .consult_sec {
    position: relative;
    padding: 140px 0 180px;

    .consult_in {
      margin-top: 80px;

      &.eng {
        table th {
          width: 20%;
        }
      }

      & table {
        border-top: 3px solid var(--color-grey-100);
        max-width: 1440px;
        width: 100%;

        & th {
          width: 15%;
          vertical-align: middle;
          text-align: left;
          padding: 0 20px;
          border-bottom: 1px solid var(--color-grey-30);

          & label {
            font-weight: 700;
            font-size: var(--font-nav-tit);
            color: var(--color-grey-100);
            line-height: 1.5;
          }
        }

        & td {
          width: 85%;
          padding: 16px 0;
          border-bottom: 1px solid var(--color-grey-30);

          & input[type='text'], & input[type='phone'] {
            width: 100%;
            height: 55px;
            line-height: 55px;
            padding: 0 30px;
            background: var(--color-grey-5);
            outline: none;
            border: none;
            font-size: var(--font-nav-sub-tit);
            font-weight: 400;
            color: var(--color-grey-100);

            &::placeholder {
              font-size: var(--font-nav-tit);
              font-weight: 400;
              color: var(--color-grey-60);
            }
          }

          & input[type='file'] {
            display: none;
          }

          & .cs_file_box {
            & label > .custom_file {
              display: flex;
              align-items: center;
              gap: 0 20px;

              & .file_name {
                display: block;
                width: 50%;
                height: 55px;
                line-height: 55px;
                padding: 0 30px;
                font-size: var(--font-nav-sub-tit);
                font-weight: 400;
                color: var(--color-grey-100);
                background: var(--color-grey-5);
              }

              & .file_btn {
                cursor: pointer;
                width: 120px;
                height: 55px;
                line-height: 55px;
                font-weight: 700;
                text-align: center;
                font-size: var(--font-nav-sub-tit);
                color: var(--color-white);
                background: var(--primary-color);
              }
            }
          }

          & .files_info {
            margin-top: 25px;
            display: block;
            font-weight: 400;
            font-size: var(--font-nav-tit);
            color: var(--color-grey-70);
          }

          & textarea {
            padding: 18px 30px;
            width: 100%;
            font-size: var(--font-nav-tit);
            font-weight: 400;
            line-height: 28px;
            height:275px;
            resize:none;
            color: var(--color-grey-100);
            background: var(--color-grey-5);
            outline: none;
            border: none;
          }
        }
      }

      & .privacy_in {
        margin-top: 100px;

        & h6 {
          font-weight: 700;
          font-size: var(--font-main-sub-tit);
          color: var(--color-grey-100);
        }

        & .terms_desc {
          margin-top: 25px;
          max-width: 100%;
          padding: 30px 20px;
          border: 1px solid var(--color-grey-30);

          p {
            font-weight: 400;
            font-size: var(--font-page-s);
            line-height: 1.5;
            color: var(--color-grey-80);
          }
        }

        & .terms_check {
          margin-top: 25px;
          padding: 0 20px;

          & input {
            display: none;
          }

          & label {
            cursor: pointer;
            position: relative;
            font-weight: 600;
            display: flex;
            align-items: center;
            gap: 0 16px;
            font-size: var(--font-nav-sub-tit);
            color: var(--color-grey-100);

            &::before {
              content: '';
              display: block;
              width: 26px;
              height: 26px;
              background: url('https://ust-kr.s3.ap-northeast-2.amazonaws.com/assets/support/check_off.svg') no-repeat;
            }
          }

          & :checked + label::before {
            background-image: url('https://ust-kr.s3.ap-northeast-2.amazonaws.com/assets/support/check_on.svg');
          }
        }
      }

      & .consult_btn {
        margin-top: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 0 18px;

        & a {
          width: 150px;
          height: 55px;
          line-height: 55px;
          font-weight: 700;
          font-size: var(--font-nav-sub-tit);
          color: var(--color-white);
          text-align: center;
          background: var(--color-grey-40);
          border-radius: 100px;
        }

        & button {
          width: 150px;
          height: 55px;
          line-height: 55px;
          font-weight: 700;
          font-size: var(--font-nav-sub-tit);
          color: var(--color-white);
          text-align: center;
          background: var(--primary-color);
          border-radius: 100px;
        }
      }
    }
  }

  @media only screen and (max-width: 1024px) {
    .consult_sec {
      position: relative;
      padding: 70px 0 90px;

      .consult_in {
        margin-top: 40px;

        &.eng {
          table th {
            width: 20%;
          }
        }

        & table {
          max-width: none;

          & th {
            width: 20%;
          }

          & td {
            width: 80%;

            & input[type='text'], & input[type='phone'] {
              width: 100%;
              height: 40px;
              line-height: 40px;
              padding: 0 16px;
            }

            & .cs_file_box {
              & label > .custom_file {
                gap: 0 12px;

                & .file_name {
                  flex: 1;
                  height: 40px;
                  line-height: 40px;
                  padding: 0 16px;
                }

                & .file_btn {
                  width: 75px;
                  height: 40px;
                  line-height: 40px;
                }
              }
            }                                                                 

            & .files_info {
              margin-top: 15px;
            }

            & textarea {
              padding: 12px 16px;
            }
          }
        }

        & .privacy_in {
          margin-top: 60px;

          & .terms_desc {
            padding: 20px 16px;
          }

          & .terms_check {

            & input {
              display: none;
            }
          }
        }

        & .consult_btn {
          margin-top: 45px;
        }
      }
    }
  }

  @media only screen and (max-width: 540px) {
    .consult_sec {
      position: relative;
      padding: 35px 0 45px;

      .consult_in {
        margin-top: 20px;

        & table {
          & th {
            padding: 0 10px;
          }

          & td {
            padding: 10px 0 10px 10px;
          }
        }

        & .privacy_in {
          margin-top: 30px;

          & .terms_check {
            margin-top: 16px;
            padding: 0;

            & input {
              display: none;
            }

            & label {
              gap: 0 8px;

              &::before {
                width: 18px;
                height: 18px;
                background-size: 18px 18px;
              }
            }
          }
        }

        & .consult_btn {
          margin-top: 25px;

          button {
            height: 40px;
            line-height: 40px;
          }
        }
      }
    }
  }
`;

export default SupportConsult;