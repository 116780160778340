import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const HeaderLayout = props => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1025);
  const [navOpen, setNavOpen] = useState(false);
  const [lang, setLang] = useState('');

  const { t, i18n } = useTranslation();

  // Nav 마우스 Enter & Leaver 컨트롤
  const [activeMenu, setActiveMenu] = useState(null);
  // 햄버거 버튼 풀 메뉴 ON & OFF
  const [activeBurger, setActiveBurger] = useState(false);
  // 드랍 메뉴
  const [activeDropName, setActiveDropName] = useState('KOR');
  const [activeDrop, setActiveDrop] = useState(false);
  const [prevScrollpos, setPrevScrollpos] = useState(window.pageYOffset);

  const handleResize = () => {
    setIsMobile(window.innerWidth < 1025);
  };
  const handleScroll = () => {
    const currentScrollPos = window.pageYOffset;

    setPrevScrollpos(currentScrollPos);
  };

  const changeLang = useCallback((language) => {
    // changeLanguage 로직
    console.log(`Language changed to: ${language}`);
  }, []);

  useEffect(() => {
    changeLang(i18n.language);

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [i18n.language, changeLang]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [prevScrollpos]);

  useEffect(() => {
    if (activeBurger === true) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [activeBurger]);

  const handleMouseEnter = (menuItem) => {
    setActiveMenu(menuItem);
  };
  const handleMouseLeave = () => {
    setActiveMenu(null);
  };
  const ActiveFullMenu = () => {
    setActiveBurger(state => !state);
  };
  const NavClick = () => {
    setActiveMenu(null);
  };
  const MoLinkClick = () => {
    setActiveBurger(false);
    setNavOpen(0);
  };
  const ActiveDropBtn = () => {
    setActiveDrop(state => !state);
  };
  const LeaveDrop = () => {
    setActiveDrop(false);
  };
  const changeLanguage = lan => {
    i18n.changeLanguage(lan).then(() => {
      switch (lan) {
        case 'ko':
          setActiveDropName(t('header.language.ko'));
          setLang('ko');
          break;
        case 'en':
          setActiveDropName(t('header.language.en'));
          setLang('en');
          break;
        case 'cn':
          setActiveDropName(t('header.language.cn'));
          setLang('cn');
          break;
        default:
          break;
      }

      setActiveBurger(false);
      setNavOpen(0);
      LeaveDrop();
    });
  };

  const toggleNav = (navNumber) => {
    if (isMobile) {
      setNavOpen(prevState => ({
        ...prevState,
        [navNumber]: !prevState[navNumber]
      }));
    }
  };

  return (
    <React.Fragment>
      <HeaderWrap
        className={`${activeMenu !== null ? 'active' : ''} ${window.innerWidth < 1025 && activeBurger ? 'active' : ''}`}
        onMouseLeave={handleMouseLeave}
        $Scroll={props.$Scroll}
        $Down={props.$Down}
      >
        <div className='inner'>
          {/* 헤더 로고 영역 */}
          <div className={activeBurger === true ? 'head_logo active' : 'head_logo'}>
            <h1>
              <Link to='/'>
                <img src='https://ust-kr.s3.ap-northeast-2.amazonaws.com/assets/main/top_logo.svg' width={120} height={24} alt='UST Logo'/>
              </Link>
            </h1>
          </div>
          {/* 헤더 GNB Navi */}
          <div className='head_gnb'>
            <nav id='gnb' aria-label='gnb_menu'>
              <ul className='menu-list first-floor'>
                <li onMouseEnter={() => handleMouseEnter('company')}>
                  <Link
                    onClick={NavClick}
                    to='/company/outline'
                    className={activeMenu === 'company' ? 'active' : ''}
                  >
                    Company
                  </Link>
                  <ul className='second-floor'>
                    <li><Link onClick={NavClick} to='/company/outline'>{t('header.company.outline')}</Link></li>
                    <li><Link onClick={NavClick} to='/company/ceo'>{t('header.company.ceo')}</Link></li>
                    <li><Link onClick={NavClick} to='/company/history'>{t('header.company.history')}</Link></li>
                    <li><Link onClick={NavClick} to='/company/business'>{t('header.company.business')}</Link></li>
                    <li><Link onClick={NavClick} to='/company/certificate'>{t('header.company.certificate')}</Link></li>
                    <li><Link onClick={NavClick} to='/company/way'>{t('header.company.wayToCome')}</Link></li>
                  </ul>
                </li>
                <li onMouseEnter={() => handleMouseEnter('solution')}>
                  <Link
                    onClick={NavClick}
                    to='/solution/spiral'
                    className={activeMenu === 'solution' ? 'active' : ''}
                  >
                    Solution
                  </Link>
                  <ul className='second-floor'>
                    <li>
                      <Link onClick={NavClick} to='/solution/spiral'>Spiral Gasket</Link>
                      {/*<ul>*/}
                      {/*  <li>*/}
                      {/*    <Link onClick={NavClick} to='/solution/spiral' state={{ sectionId : "type-normal" }} >Normal Type</Link>*/}
                      {/*  </li>*/}
                      {/*  <li>*/}
                      {/*    <Link onClick={NavClick} to='/solution/spiral' state={{ sectionId : "type-form" }} >Form Type</Link>*/}
                      {/*  </li>*/}
                      {/*</ul>*/}
                    </li>
                    <li><Link onClick={NavClick} to='/solution/pad'>Pad Gasket</Link></li>
                  </ul>
                </li>
                <li onMouseEnter={() => handleMouseEnter('recruit')}>
                  <Link
                    onClick={NavClick}
                    to='/recruit/talent'
                    className={activeMenu === 'recruit' ? 'active' : ''}
                  >
                    Recruit
                  </Link>
                  <ul className='second-floor'>
                    <li><Link onClick={NavClick} to='/recruit/talent'>{t('header.recruit.talent')}</Link></li>
                    <li><Link onClick={NavClick} to='/recruit/benefit'>{t('header.recruit.benefit')}</Link></li>
                  </ul>
                </li>
                <li onMouseEnter={() => handleMouseEnter('support')}>
                  <Link
                    onClick={NavClick}
                    to='/support/notice/list'
                    className={activeMenu === 'support' ? 'active' : ''}
                  >
                    Support
                  </Link>
                  <ul className='second-floor'>
                    <li><Link onClick={NavClick} to='/support/notice/list'>{t('header.support.notice')}</Link></li>
                    <li><Link onClick={NavClick} to='/support/consult'>{t('header.support.consult')}</Link></li>
                  </ul>
                </li>
              </ul>
            </nav>
          </div>
          {/* 헤더 언어 변경 & 햄버거 메뉴 */}
          <div className='head_menu' onMouseLeave={LeaveDrop}>
            <div className='language dropdown'>
              <button onClick={ActiveDropBtn} type='button' aria-label='language_button' className='lang_btn'>{activeDropName}</button>
              <div className={activeDrop === true ? 'dropdownBox on' : 'dropdownBox'}>
                <div className='dropIn'>
                  <ul>
                    <li>
                      <button onClick={() => changeLanguage('ko')}>
                        {t('header.language.ko')}
                      </button>
                    </li>
                    <li>
                      <button onClick={() => changeLanguage('en')}>
                        {t('header.language.en')}
                      </button>
                    </li>
                    <li>
                      <button onClick={() => changeLanguage('cn')}>
                        {t('header.language.cn')}
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className={activeBurger === true ? 'hamburger active' : 'hamburger'}>
              <button onClick={ActiveFullMenu} className={activeBurger === true ? 'open menu_btn' : 'menu_btn'} aria-labelledby='hamburger_button'>
                <div className='icon-left'/>
                <div className='icon-right'/>
              </button>
            </div>

            {/* 풀 메뉴 */}
            <MainMenu className={activeBurger === true ? 'active' : ''}>
              <div className='menu_inner'>
                {isMobile &&
                  <div className='global_flag'>
                    <ul>
                      <li>
                        <button className={lang === 'ko' ? 'active' : ''}
                                onClick={() => changeLanguage('ko')}>{t('header.language.ko')}</button>
                      </li>
                      <li>
                        <button className={lang === 'en' ? 'active' : ''}
                                onClick={() => changeLanguage('en')}>{t('header.language.en')}</button>
                      </li>
                      <li>
                        <button className={lang === 'cn' ? 'active' : ''}
                                onClick={() => changeLanguage('cn')}>{t('header.language.cn')}</button>
                      </li>
                    </ul>
                  </div>
                }
                <ul>
                  <li>
                    <strong onClick={() => toggleNav(1)} className={navOpen[1] ? 'active' : ''}>Company</strong>
                    <ul className={navOpen[1] ? 'active' : ''}>
                      <li><Link onClick={MoLinkClick} to='/company/outline'>{t('header.company.outline')}</Link></li>
                      <li><Link onClick={MoLinkClick} to='/company/ceo'>{t('header.company.ceo')}</Link></li>
                      <li><Link onClick={MoLinkClick} to='/company/history'>{t('header.company.history')}</Link></li>
                      <li><Link onClick={MoLinkClick} to='/company/business'>{t('header.company.business')}</Link></li>
                      <li><Link onClick={MoLinkClick} to='/company/certificate'>{t('header.company.certificate')}</Link>
                      </li>
                      {/*<li><Link to='/company/global'>{t('header.company.globalBusiness')}</Link></li>*/}
                      <li><Link onClick={MoLinkClick} to='/company/way'>{t('header.company.wayToCome')}</Link></li>
                    </ul>
                  </li>
                  <li>
                    <strong onClick={() => toggleNav(2)} className={navOpen[2] ? 'active' : ''}>Solution</strong>
                    <ul className={navOpen[2] ? 'active' : ''}>
                      <li><Link onClick={MoLinkClick} to='/solution/spiral'>Spiral Gasket</Link></li>
                      <li><Link onClick={MoLinkClick} to='/solution/pad'>Pad Gasket</Link></li>
                    </ul>
                  </li>
                  <li>
                    <strong onClick={() => toggleNav(3)} className={navOpen[3] ? 'active' : ''}>Recruit</strong>
                    <ul className={navOpen[3] ? 'active' : ''}>
                      <li><Link onClick={MoLinkClick} to='/recruit/talent'>{t('header.recruit.talent')}</Link></li>
                      <li><Link onClick={MoLinkClick} to='/recruit/benefit'>{t('header.recruit.benefit')}</Link></li>
                    </ul>
                  </li>
                  <li>
                    <strong onClick={() => toggleNav(4)} className={navOpen[4] ? 'active' : ''}>Support</strong>
                    <ul className={navOpen[4] ? 'active' : ''}>
                      <li><Link onClick={MoLinkClick} to='/support/notice/list'>{t('header.support.notice')}</Link></li>
                      <li><Link onClick={MoLinkClick} to='/support/consult'>{t('header.support.consult')}</Link></li>
                      {/*<li><Link to='/support/reference/list'>{t('header.support.reference')}</Link></li>*/}
                    </ul>
                  </li>
                </ul>
              </div>
            </MainMenu>
          </div>
          {/* 서브 메뉴 백그라운드*/}
        </div>
        <div className='head_sub_bg'/>
      </HeaderWrap>
    </React.Fragment>
  );
};

const HeaderWrap = styled.header`
  z-index: 99;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: var(--size-header);
  background: ${props => {
  if (props.$Scroll) {
    return 'var(--color-white)';
  } else if (props.$Down === true) {
    return 'var(--color-white)';
  } else {
    return 'var(--color-clear)';
  }
}};
  transform: ${props => {
  if (props.$Scroll) {
    return 'none';
  } else if (props.$Down === false) {
    return 'translateY(-90px)';
  } else {
    return 'none'
  }
}};

  transition: transform .3s ease-in-out;


  & .inner {
    z-index: 2;
    position: relative;
    display: grid;
    grid-template-columns: 320px calc(100% - 640px) 320px;
    transition: var(--transition);
    border-bottom: ${props => {
  if (props.$Scroll || props.$Down === true) {
    return '1px solid var(--color-grey-10)';
  } else {
    return '1px solid var(--color-clear)';
  }
}};


    // Logo
    & .head_logo {
      z-index: 2;
      margin-left: 45px;
      position: relative;
      display: flex;
      align-items: center;
      height: var(--size-header);

      & img {
        width: var(--size-logo);
        filter: ${props => {
  if (props.$Scroll || props.$Down === true) {
    return 'none';
  } else {
    return 'grayscale(1) brightness(5)';
  }
}};
      }


      &.active img {
        filter: none;
      }
    }

    // Nav
    & .head_gnb, & #gnb {
      z-index: 2;
      position: relative;
      width: 100%;
      height: var(--size-header);

      & .menu-list {
        display: flex;
        justify-content: center;

        &.first-floor > li > a {
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          padding-left: var(--spacing-24);
          padding-right: var(--spacing-24);
          height: var(--size-header);
          transition: var(--transition);
          font-weight: 700;
          font-size: var(--font-nav-tit);
          color: ${props => {
  if (props.$Scroll || props.$Down === true) {
    return 'var(--color-grey-100)';
  } else {
    return 'var(--color-white)';
  }
}};


          &::before {
            content: '';
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            width: 0;
            height: 3px;
            background: var(--primary-color);
            transition: var(--transition);
          }

          &.active::before {
            width: 100%;
          }
        }

        & .second-floor {
          display: none;
          opacity: 0;
          margin-top: var(--spacing-40);
          transition: var(--transition);

          & > li {
            > a {
              margin-top: var(--spacing-12);
              display: block;
              font-weight: 500;
              font-size: var(--font-nav-sub-tit);
              color: var(--color-white);
              text-align: center;

              &:hover {
                color: var(--primary-color);
              }
            }

            + li {
              margin-top: 25px;
            }
            
            ul li {
              margin-top: 12px;
              
              a {
                display: block;
                font-weight: 500;
                font-size: var(--font-page-tit);
                color: var(--color-grey-80);                
                text-align: center;

                &:hover {
                  color: var(--primary-color);
                }
              }
            }
          }
        }
      }
    }

    // Hamburger Menu & Drop Menu
    & .head_menu {
      z-index: 2;
      margin-right: 45px;
      position: relative;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 0 24px;
      height: var(--size-header);


      // 드롭 메뉴
      & .dropdown {
        position: relative;
        padding: 0 25px;

        // 드랍 버튼
        & > button {
          position: relative;
          display: flex;
          align-items: center;
          height: 30px;
          font-weight: 500;
          font-size: var(--font-nav-sub-tit);
          color: ${props => {
  if (props.$Scroll || props.$Down === true) {
    return 'var(--color-grey-100)';
  } else {
    return 'var(--color-white)';
  }
}};

          &::after {
            content: '';
            width: 30px;
            height: 30px;
            background: ${props => {
  if (props.$Scroll || props.$Down === true) {
    return 'url("https://ust-kr.s3.ap-northeast-2.amazonaws.com/assets/main/drop_arrow.svg") no-repeat';
  } else {
    return 'url("https://ust-kr.s3.ap-northeast-2.amazonaws.com/assets/main/drop_arrow_w.svg") no-repeat';
  }
}};
            background-size: auto auto;
          }
        }

        // 드랍 컨텐츠
        & .dropdownBox {
          height: 0;
          opacity: 0;
          overflow: hidden;
          position: absolute;
          top: calc(100% + 10px);
          left: 50%;
          width: 100%;
          transform: var(--translate-align-x);
          background: var(--color-white);
          border: 1px solid var(--color-grey-50);
          transition: var(--transition);

          &.on {
            height: 120px;
            opacity: 1;
          }

          & button {
            display: block;
            width: 100%;
            min-height: 40px;
            font-weight: 500;
            font-size: var(--font-nav-sub-tit);
            color: var(--color-grey-80);
            text-align: center;
            cursor: pointer;

            &:hover {
              color: var(--primary-color);
            }
          }
        }
      }

      // 햄버거 메뉴
      & .hamburger {
        z-index: 99;
        position: relative;

        & button {
          position: relative;
          width: 50px;
          height: 50px;
          transition-property: top, bottom, left, right, opacity, transform;
          transition-duration: 0.5s;

          & .icon-left {
            position: absolute;
            top: 25px;
            left: calc(50% - 6px);
            transform: translateX(-50%);
            width: 12px;
            height: 2px;
            background: ${props => {
  if (props.$Scroll || props.$Down === true) {
    return 'var(--color-grey-100)';
  } else {
    return 'var(--color-white)';
  }
}};
            transition-property: top, bottom, left, right, opacity, transform;
            transition-duration: 0.5s;

            &::before {
              content: '';
              position: absolute;
              left: 0;
              top: -7px;
              width: 12px;
              height: 2px;
              background: ${props => {
  if (props.$Scroll || props.$Down === true) {
    return 'var(--color-grey-100)';
  } else {
    return 'var(--color-white)';
  }
}};
              transition-duration: 0.5s;
              transition-property: top, bottom, left, right, opacity, transform;
            }

            &::after {
              content: '';
              position: absolute;
              left: 0;
              top: 7px;
              width: 12px;
              height: 2px;
              background: ${props => {
  if (props.$Scroll || props.$Down === true) {
    return 'var(--color-grey-100)';
  } else {
    return 'var(--color-white)';
  }
}};
              transition-duration: 0.5s;
              transition-property: top, bottom, left, right, opacity, transform;
            }
          }

          & .icon-right {
            position: absolute;
            top: 25px;
            left: calc(50% + 6px);
            transform: translateX(-50%);
            width: 12px;
            height: 2px;
            background: ${props => {
  if (props.$Scroll || props.$Down === true) {
    return 'var(--color-grey-100)';
  } else {
    return 'var(--color-white)';
  }
}};
            transition-duration: 0.5s;
            transition-property: top, bottom, left, right, opacity, transform;

            &::before {
              content: '';
              position: absolute;
              left: 0;
              top: -7px;
              width: 12px;
              height: 2px;
              background: ${props => {
  if (props.$Scroll || props.$Down === true) {
    return 'var(--color-grey-100)';
  } else {
    return 'var(--color-white)';
  }
}};
              transition-duration: 0.5s;
              transition-property: top, bottom, left, right, opacity, transform;
            }

            &::after {
              content: '';
              position: absolute;
              left: 0;
              top: 7px;
              width: 12px;
              height: 2px;
              background: ${props => {
  if (props.$Scroll || props.$Down === true) {
    return 'var(--color-grey-100)';
  } else {
    return 'var(--color-white)';
  }
}};
              transition-duration: 0.5s;
              transition-property: top, bottom, left, right, opacity, transform;
            }
          }
        }

        & button.open {
          position: relative;
          width: 50px;
          height: 50px;

          & .icon-left {
            background: transparent;

            &::before {
              transform: rotateZ(45deg) scaleX(1.4) translate(2px, 2px);
              top: -12px
            }

            &::after {
              transform: rotateZ(-45deg) scaleX(1.4) translate(2px, -2px);
              top: 6px;
            }
          }

          & .icon-right {
            transition-duration: 0.5s;
            background: transparent;

            &::before {
              transform: rotateZ(-45deg) scaleX(1.4) translate(-2px, 2px);
              top: -12px;
            }

            &::after {
              transform: rotateZ(45deg) scaleX(1.4) translate(-2px, -2px);
              top: 6px;
            }
          }
        }

        &.active {
          & .icon-left {
            background: var(--color-grey-100);

            &::before {
              background: var(--color-grey-100);
            }

            &::after {
              background: var(--color-grey-100);
            }
          }

          & .icon-right {
            background: var(--color-grey-100);

            &::before {
              background: var(--color-grey-100);
            }

            &::after {
              background: var(--color-grey-100);
            }
          }
        }
      }
    }
  }

  // 서브 메뉴 ON 백그라운드
  & .head_sub_bg {
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 0;
    background: transparent;
    transition: all .2s ease-in-out;
  }

  &.active {
    & .inner {
      border-bottom: 1px solid var(--color-grey-10);

      // Logo
      & .head_logo img {
        filter: none;
      }

      // Nav
      & .head_gnb, & #gnb {
        .first-floor > li > a {
          padding-left: var(--spacing-60);
          padding-right: var(--spacing-60);
          color: var(--color-grey-100);
        }

        & .second-floor {
          display: block;
          opacity: 1;

          & > li > a {
            color: var(--color-grey-80);
          }
        }
      }

      // Hamburger Menu & Drop Menu
      & .head_menu {

        // 드롭 메뉴
        & .dropdown {
          // 드랍 버튼
          & > button {
            color: var(--color-grey-100);

            &::after {
              background: url('https://ust-kr.s3.ap-northeast-2.amazonaws.com/assets/main/drop_arrow.svg') no-repeat;
            }
          }
        }

        // 햄버거 메뉴
        & .hamburger {

          & .icon-left {
            background: var(--color-grey-100);

            &::before {
              background: var(--color-grey-100);
            }

            &::after {
              background: var(--color-grey-100);
            }
          }

          & .icon-right {
            background: var(--color-grey-100);

            &::before {
              background: var(--color-grey-100);
            }

            &::after {
              background: var(--color-grey-100);
            }
          }
        }
      }
    }

    // 서브 메뉴 ON 백그라운드
    & .head_sub_bg {
      height: 450px;
      background: var(--color-white);
    }
  }

  @media only screen and (max-width: 1024px) {
    & .inner {
      display: flex;
      justify-content: space-between;
      padding: 0 20px;

      .head_logo {
        z-index: 3;
        margin: 0;
      }

      .head_gnb {
        display: none;
      }

      .head_menu {
        margin: 0;

        .hamburger.active {
          transform: scale(.7);
        }

        .dropdown {
          display: none;
        }
      }
    }
  }
`

const MainMenu = styled.div`
  z-index: 97;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: #ffffff;
  opacity: 0;
  visibility: hidden;
  transition: var(--transition);

  &.active {
    opacity: 1;
    visibility: visible;
  }

  .menu_inner {
    & > ul {
      position: relative;
      height: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;

      & > li {
        width: 22.65%;
        height: 100%;
        padding-top: 10%;
        text-align: center;
        border-right: 1px solid var(--color-grey-30);

        &:last-child {
          border-right: none;
        }

        & strong {
          font-weight: 700;
          font-size: var(--font-24);
          color: var(--color-grey-100)
        }

        & ul {
          margin-top: 80px;

          & > li {
            min-height: 30px;

            & + li {
              margin-top: 30px;
            }

            & a {
              display: block;
              font-size: var(--font-nav-sub-tit);
              font-weight: 500;
              color: var(--color-grey-80);

              &:hover {
                color: var(--primary-color);
              }
            }

          }
        }
      }
    }

    .global_flag {
      border-bottom: 1px solid var(--color-grey-20);
      padding: 10px 16px;
      position: relative;
      height: 60px;

      ul {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 0 16px;

        li button {
          position: relative;
          font-weight: 600;
          font-size: 14px;
          color: var(--color-gray-40);
          line-height: 30px;

          &.active {
            color: var(--primary-color);

            &::before {
              content: '';
              position: absolute;
              bottom: 0;
              left: 50%;
              transform: translateX(-50%);
              width: 100%;
              height: 2px;
              background: var(--primary-color);
            }
          }
        }
      }
    }
  }

  @media only screen and (max-width: 1024px) {

    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: #ffffff;
    opacity: 0;
    visibility: hidden;
    transition: var(--transition);

    &.active {
      opacity: 1;
      left: 0;
      visibility: visible;
    }

    .menu_inner {
      border-top: 1px solid var(--color-grey-20);
      margin-top: var(--size-header);

      & > ul {
        padding: 16px 0;
        position: relative;
        height: calc(100vh - 110px);
        display: block;
        overflow-y: auto;

        & > li {
          width: 100%;
          height: auto;
          padding: 0 20px;
          text-align: left;
          border: none;

          & strong {
            position: relative;
            cursor: pointer;
            display: block;
            padding: 25px 0;
            border-bottom: 1px solid var(--color-grey-20);

            &::before {
              content: '';
              position: absolute;
              top: 50%;
              right: 0;
              transform: translateY(-50%);
              width: 40px;
              height: 40px;
              background: url('https://ust-kr.s3.ap-northeast-2.amazonaws.com/assets/main/nav_arrow_down.svg') no-repeat;
              transition: var(--transition);
            }

            &.active::before {
              transform: translateY(-50%) rotate(-180deg);
            }
          }

          & + li {
            margin-top: 0;
          }

          & ul {
            margin-top: 0;
            opacity: 0;
            height: 0;
            overflow: hidden;
            visibility: hidden;
            transition: ease-in-out all .3s;

            &.active {
              opacity: 1;
              height: auto;
              visibility: visible;
            }

            & > li {
              min-height: 12px;
              padding: 20px;
              border-bottom: 1px solid var(--color-grey-20);

              & + li {
                margin-top: 0;
              }

              & a {
              }
            }
          }
        }
      }

    }
  }
`;

export default HeaderLayout;
